<template>
    <div
        ref="summaryStudentInfoTemplate"
        class="page"
    >
        <global-page-back
            detailPageTitle="档案模板"
            @handlerGlobalType="back"
        ></global-page-back>
        <div
            ref="container"
            class="container"
        >
            <div class="template-list-div">
                <div class="template-add-btn-div">
                    <div
                        class="template-add-btn"
                        @click="addTemplateDialogShow"
                    >
                        <img
                            :src="plusIcon"
                            alt=""
                        />
                        <span>添加档案</span>
                    </div>
                </div>
                <div class="template-list">
                    <draggable
                        :list="templateList"
                        :animation="340"
                        group="componentsGroup"
                        @change="dragChange"
                    >
                        <div
                            class="template-item"
                            v-for="(item, index) in templateList"
                            :key="index"
                            :class="
                                index == selectedTemplate
                                    ? 'template-item-selected'
                                    : ''
                            "
                            @click="templateClick(item, index)"
                        >
                            <span class="template-name">{{
                                item.archivesName
                            }}</span>
                            <img
                                class="template-item-edit"
                                :src="
                                    index == selectedTemplate
                                        ? editBlueIcon
                                        : editIcon
                                "
                                alt=""
                                @click.stop="editTemplate(item, index)"
                            />
                            <img
                                class="template-item-del"
                                :src="
                                    index == selectedTemplate
                                        ? delRedIcon
                                        : delIcon
                                "
                                alt=""
                                @click.stop="delTemplate(item, index)"
                            />
                        </div>
                    </draggable>
                </div>
            </div>

            <div class="main">
                <DesignTemplate
                    :key="
                        templateList[selectedTemplate]
                            ? templateList[selectedTemplate].id
                            : ''
                    "
                    :template-base-info="
                        templateList[selectedTemplate]
                            ? templateList[selectedTemplate]
                            : null
                    "
                    :type="type"
                />
            </div>
        </div>

        <!-- 添加或编辑模板信息弹框 -->
        <DialogWrapper
            v-if="templateDialogObj.dialogVisible"
            :dialogObj="templateDialogObj"
            @handleClose="templateDialogObj.dialogVisible = false"
        >
            <el-form
                class="common-form"
                ref="templateForm"
                :model="templateForm"
                :rules="templateRules"
                label-width="150px"
            >
                <el-form-item
                    label="模板名称"
                    prop="archivesName"
                >
                    <el-input
                        class="input-width-medium"
                        v-model="templateForm.archivesName"
                        placeholder="请输入模板名称"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="档案内容更新方式"
                    prop="updateType"
                >
                    <el-select
                        class="input-width-medium"
                        v-model="templateForm.updateType"
                        placeholder="请选择档案内容更新方式"
                        :disabled="updateTypeDisabled"
                    >
                        <el-option
                            v-for="item in updateTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                    <div class="explainText">
                        累加型：档案中的内容 ，以叠加方式逐年增加
                    </div>
                    <div class="explainText">
                        更新型：档案内容只能修改不能叠加（可用于户口信息更新）
                    </div>
                </el-form-item>
            </el-form>
            <div
                class="dialog-footer"
                align="right"
            >
                <xk-button
                    type="info"
                    @click="templateDialogObj.dialogVisible = false"
                    >取 消</xk-button
                >
                <xk-button
                    type="primary"
                    @click="templateSave"
                    >确 定</xk-button
                >
            </div>
        </DialogWrapper>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
} from "common-local";
import GlobalPageBack from '@/components/scrollWrapper/Sub/GlobalPageBack/index.vue';
import DesignTemplate from "./SummaryStudentInfoTemplate/DesignTemplate.vue";
import draggable from "vuedraggable";

export default {
    name: "",
    components: {
        DialogWrapper,
        GlobalPageBack,
        DesignTemplate,
        draggable,
    },
    props: {},
    data() {
        return {
            oriWidth: 1675,
            oriHeight: 871,
            schoolId: "",
            type: "student",
            templateList: [], // 模板列表
            selectedTemplate: -1, // 选中模板索引
            templateDialogObj: {
                title: "添加档案",
                dialogVisible: false,
                width: "auto",
            },
            templateForm: {
                archivesName: "", // 模板名称
                updateType: "", // 模板更新方式1累加型2更新型
            },
            templateRules: {
                archivesName: [
                    {
                        required: true,
                        message: "请输入模板名称",
                        trigger: "change",
                    },
                ],
                updateType: [
                    {
                        required: true,
                        message: "请选择模板内容更新方式",
                        trigger: "change",
                    },
                ],
            },
            updateTypeDisabled: false,
            updateTypeOptions: [
                {
                    label: "累加型",
                    value: "1",
                },
                {
                    label: "更新型",
                    value: "2",
                },
            ],
            formData: {},
            listQuery: {},
        };
    },
    filters: {},
    computed: {
        backIcon() {
            return require("@/assets/images/back2.png");
        },
        plusIcon() {
            return require("@/assets/images/plus.png");
        },
        editIcon() {
            return require("@/assets/images/stuInfoTemplate/edit.png");
        },
        delIcon() {
            return require("@/assets/images/stuInfoTemplate/del.png");
        },
        editBlueIcon() {
            return require("@/assets/images/stuInfoTemplate/edit-blue.png");
        },
        delRedIcon() {
            return require("@/assets/images/stuInfoTemplate/del-red.png");
        },
    },
    watch: {},
    beforeCreate() {},
    created() {
        this.$eventDispatch('setGlobalPageType', 'form')

        console.log(
            "我去到数据了",
            this.$route.query.formData,
            this.$route.query.listQuery,
        );
        this.formData = this.$route.query.formData; // 保存当前查询条件
        this.listQuery = this.$route.query.listQuery; // 保存当前查询条件
        this.schoolId = this.$route.query.schoolId;
        this.type = this.$route.query.type ? this.$route.query.type : this.type;

        this.getTemplateList();
    },
    mounted() {
        // 缩放
        // this.zoom()
        // let _this = this;
        // window.onresize = () => {
        //     return (() => {
        //         _this.zoom()
        //     })()
        // }
    },
    beforeDestroy() {},
    methods: {
        /**
         * @Description: 拖拽排序
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-06-08 15:01:07
         */
        dragChange(a) {
            console.log(a, this.templateList, "dragble");
            let templateList1 = this.templateList.map((item, index) => {
                item.sort = index + 1;
                return item;
            });
            this._fet("/school/schoolArchivesType/sort", {
                list: templateList1,
            }).then((res) => {
                console.log(res, "【档案模板拖拽成功】");
            });
            this.selectedTemplate = a.moved.newIndex;
            // console.log(templateList1, "要发送的数据");
        },
        zoom() {
            let _this = this;
            let dom = _this.$refs.container;
            dom.style.width = "100%";
            dom.style.height = "calc(100vh - 108px)";
            console.log(dom.offsetWidth, dom.offsetHeight);

            let changeWidth = dom.offsetWidth;
            let changeHeight = dom.offsetHeight;
            let x = changeWidth / _this.oriWidth;
            let y = changeHeight / _this.oriHeight;
            console.log(changeWidth, changeHeight, x, y);

            dom.style.width = _this.oriWidth + "px";
            dom.style.height = _this.oriHeight + "px";
            dom.style.transform = `scale(${x}, ${y})`;
        },
        back() {
            if (this.type == "teacher") {
                // this.$router.push('/summary/archives/summaryTeacher')
                this.$router.push({
                    name: "SummaryTeacher",
                    query: {
                        formData: this.formData, // 保存当前查询条件
                        listQuery: this.listQuery, // 保存当前查询条件
                    },
                });
            } else {
                // this.$router.push('/summary/archives/summaryStudent')
                this.$router.push({
                    name: "SummaryStudent",
                    query: {
                        formData: this.formData, // 保存当前查询条件
                        listQuery: this.listQuery, // 保存当前查询条件
                    },
                });
            }
            this.$eventDispatch('setGlobalPageType', 'list')
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === "200") {
                fn();
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg);
            }
        },
        // 获取模板列表
        getTemplateList() {
            this._fet("/school/schoolArchivesType/listByCondition", {
                schoolId: this.schoolId,
                archivesPersonType: this.type === "teacher" ? "2" : "1", // 档案应用人类型 1学生档案 2教师档案
            }).then((res) => {
                console.log("[获取模板列表]接口返回：", res);
                this.handleRes(res, () => {
                    this.templateList = res.data.data;
                    if (this.templateList.length > 0) {
                        this.selectedTemplate = 0;
                    }
                });
            });
        },
        // 显示添加模板弹窗
        addTemplateDialogShow() {
            this.templateForm = {};
            this.updateTypeDisabled = false;
            this.templateDialogObj.title = "添加档案";
            this.templateDialogObj.dialogVisible = true;
        },
        // 编辑模板
        editTemplate(item, index) {
            console.log(
                "[编辑模板基础信息]this.templateForm",
                this.templateForm,
            );
            // this.templateClick(item, index)
            this.templateForm = JSON.parse(JSON.stringify(item));
            this.updateTypeDisabled = true;
            this.templateDialogObj.title = "编辑档案";
            this.templateDialogObj.dialogVisible = true;
        },
        // 删除模板
        delTemplate(item, index) {
            this.$confirm("确定删除此模板？", "确认", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 确定
                    this._fet(
                        "/school/schoolArchivesType/delete",
                        [
                            {
                                id: item.id, 
                                schoolId: this.schoolId,
                                archivesPersonType: this.type === "teacher" ? "2" : "1", // 档案应用人类型 1学生档案 2教师档案
                            }
                        ],
                        {isArrParams: true},
                    ).then((res) => {
                        this.handleRes(res, () => {
                            this.getTemplateList();
                            if (index == this.selectedTemplate) {
                                this.selectedTemplate = -1;
                            }
                            this.$message.success("删除成功");
                        });
                    });
                })
                .catch(() => {
                    // 取消
                });
        },
        // 添加模板
        templateSave() {
            console.log(
                "[保存模板基础信息]this.templateForm",
                this.templateForm,
            );
            this.$refs.templateForm.validate((valid) => {
                if (valid) {
                    this._fet(
                        "/school/schoolArchivesType/save",
                        Object.assign(
                            {
                                schoolId: this.schoolId,
                                archivesPersonType:
                                    this.type === "teacher" ? "2" : "1", // 档案应用人类型 1学生档案 2教师档案
                            },
                            this.templateForm,
                        ),
                    ).then((res) => {
                        console.log("[添加模板]接口返回：", res);
                        this.handleRes(res, () => {
                            this.getTemplateList();
                            this.$message.success("操作成功");
                            this.templateDialogObj.dialogVisible = false;
                        });
                    });
                }
            });
        },
        // 点击模板
        templateClick(item, index) {
            this.selectedTemplate = index;
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    /* height: calc(100vh - 108px); */
    height: calc(100vh - 120px);
    padding: 16px 16px 16px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    /* background: #e6e8ea; */
    transform-origin: 0px 0px;
}

.template-list-div {
    width: 200px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    .back {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 12px;
        border-bottom: 1px solid #e6e6e6;
        cursor: pointer;

        img {
            width: 8px;
            height: 12px;
            object-fit: contain;
        }

        span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #595959;
            margin-left: 5px;
        }
    }

    .template-add-btn-div {
        padding: 10px;
        border-bottom: 1px solid #e6e6e6;

        .template-add-btn {
            height: 40px;
            background: #3C7FFF;
            border-radius: 2px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;

            img {
                width: 12px;
                height: 12px;
            }

            span {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-left: 8px;
            }
        }
    }

    .template-list {
        display: flex;
        flex-direction: column;
        padding: 6px 10px;
        flex: 1;
        overflow-y: auto;
        flex-shrink: 0;

        .template-item {
            height: 48px;
            border-radius: 4px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid transparent;
            padding: 0 13px 0 16px;
            cursor: pointer;
            flex-shrink: 0;

            .template-name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #595959;
            }

            .template-item-edit {
                width: 13px;
                height: 13px;
                margin-left: auto;
            }

            .template-item-del {
                width: 14px;
                height: 13px;
                margin-left: 16px;
            }
        }

        .template-item-selected {
            background: #e6f0fa;
            border: 1px solid #3C7FFF;

            .template-name {
                color: #3C7FFF;
            }
        }
    }
}

// 添加编辑模板基本信息弹窗-说明文字
.explainText {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}

.main {
    margin-left: 10px;
    flex: 1;
}
</style>
